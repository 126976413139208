.full_row span {
  font-size: 12px;
}

.smstext {
  font-size: 12px;
}
@media screen and (max-width: 424px) {
  .modal-body {
    padding-bottom: 0 !important;
  }

  .modal-content input {
    font-size: 11px;
  }

  .modal-content p:nth-child(1) {
    padding: 0 11px;
    font-size: 1.25rem;
    margin-bottom: 12px;
  }

  .topViewCont .txtCont .tit {
    font-size: 16px !important;
  }

  .topViewCont {
    padding: 0 0 15px !important;
  }

  .modal-content h4:nth-child(1) {
    font-size: 13px;
    margin: 0 0 12px;
  }

  .modal-content ul li {
    font-size: 12px;
  }

  .full_row span {
    font-size: 10px;
  }

  .writeForm {
    margin-bottom: 0 !important;
  }

  .smstext {
    margin-top: 5px;
    display: block;
    font-size: 10px;
  }
  .modal-footer {
    margin-top: 10px !important;
  }
}
